.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

header {
  position: relative;
  width: 100%;
  height: 5.5rem;
  color: white;
  display: flex;
  margin-top: 20px;
  padding-top: 0.5rem;
}

span.ant-collapse-header-text {
  color: #3179cb;
  font-weight: bold;
  font-size: 24px;
}
a.ant-anchor-link-title {
  font-weight: bold;
}
.ant-collapse-content-box {
  margin-top: -20px;
}

header button:hover {
  color: black;
  background-color: #ffffff;
  filter: drop-shadow(0 0 5px #ffffff) contrast(2) brightness(2);
}

p.text {
  font-size: 17px;
  align-items: center;
  text-align: left;
  margin-inline: 75px;
  color: #707070;
  margin-top: -5px;
}

button.ant-btn.css-dev-only-do-not-override-1g853jt.ant-btn-default.button {
  width: 155px;
  height: 62px;
  border-radius: 10px;
  border: 1px solid #707070;
  background-color: #478cf7;
  color: #fff;
  font-size: 16px;
}

input.ant-input.css-dev-only-do-not-override-1g853jt {
  width: 180px;
  height: 62px;
  border-radius: 10px;
  border: 1px solid #eaeaed;
}
body {
  position: relative;
  width: 100%;
  background-image: linear-gradient(to right, #326cac, #329fe2);
  color: white;
}
@media (max-width: 768px) {
  header .menu {
    position: absolute;
    right: 3px !important;
    width: 99vw !important;
    height: 225px;
  }
  header .headerCell {
    padding-inline: 10px !important;
  }
  header .acnhor {
    overflow: auto !important;
    white-space: nowrap !important;
    width: 90vw !important;
  }

  header .text {
    font-size: 20px !important;
  }

  span.ant-collapse-header-text {
    color: #3179cb;
    font-weight: bold;
    font-size: 18px;
  }

  svg {
    color: #3179cb;
    font-size: 24px !important;
  }

  body .modal_h {
    height: 100% !important;
  }

  body p.text {
    margin-inline: 0px !important;
  }

  body .textCell {
    font-size: 28px !important;
  }

  body .h1mobi {
    font-size: 30px !important;
    margin-top: -20px !important;
  }
  body .textrespon {
    width: 80% !important;
  }
  body .textrespon1 {
    width: 100% !important;
  }

  body .formModal {
    width: 90% !important;
    margin-inline: 0px !important;
  }

  body .formModal1 {
    flex-direction: column !important;
  }

  body .div1 {
    flex-direction: column;
    margin-top: 50px;
  }
  body .img {
    margin-left: 1vw !important;
    margin-top: -25px !important;
    width: 90vw !important;
    height: 100vw !important;
  }
  body .div2 {
    margin-left: 30px !important;
    width: 90vw !important;
    margin-top: -120px !important;
  }

  body .div3 {
    flex-direction: column;
    margin-right: auto !important;
    margin-left: auto !important;
    cursor: pointer !important;
  }

  body .p1 {
    width: 90vw !important;
  }

  body .div4 {
    margin-left: 18px !important;
    margin-top: -50px !important;
  }

  body .div5 {
    margin-left: 30px !important;
    margin-top: -10px !important;
    width: 90vw !important;
  }

  body .div6 {
    flex-direction: column;
    align-items: center;
    width: 90vw !important;
  }

  body .div7 {
    width: 90vw !important;
  }

  body .div8 {
    width: 90vw !important;
  }

  body .div9 {
    flex-direction: column;
    margin-top: 40px !important;
  }

  body .div10 {
    margin-left: 0px !important;
    margin-top: -10px !important;
    width: 90vw !important;
  }
  body .nametg {
    width: 90% !important;
  }

  body .req {
    margin-left: -15px !important;
  }

  footer .footerCell {
    flex-direction: column;
  }
  footer .img {
    margin-left: 0px !important;
    margin-top: -25px !important;
    width: 277px !important;
    height: 50px !important;
  }
}

svg {
  color: #3179cb;
  font-size: 30px;
}

footer {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 20rem;
  background-color: #015dc0;
  color: white;
  text-align: center;
  padding-top: 0.5rem;
}

footer a {
  color: #61dafb;
}

footer a:hover {
  color: #61dafb;
  text-decoration: none;
}

footer p {
  margin: 0;
}

footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

footer li {
  display: inline-block;
  margin: 0 0.5rem;
}

footer li:first-child {
  margin-left: 0;
}

footer li:last-child {
  margin-right: 0;
}

footer svg {
  width: 1.5rem;
  height: 1.5rem;
  fill: white;
}

footer svg:hover {
  fill: #61dafb;
}

footer .footer-logo {
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 0.5rem;
  fill: white;
}

footer .footer-logo:hover {
  fill: #61dafb;
}

footer .footer-logo-text {
  font-size: 1.5rem;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

footer .footer-logo-text:hover {
  color: #61dafb;
  text-decoration: none;
}

footer .footer-logo-text span {
  font-weight: 300;
}

footer .footer-logo-text span:first-child {
  font-weight: bold;
}
